.widget {
  .extended-settings {
    .collapse-toggle {
      cursor: pointer;
    }
  }

  .op-widget-description {
    display:      block;
    font-size:    11px;
    line-height:  17px;
    color:        #666;
  }
}