table.op-holidays {
  width: 100%;

  .col-name {
    width: 50%;
  }

  .col-date-start, .col-date-end {
    width: 20%;
  }

  .col-remove {
    width: 10%;

    .remove-holiday {
      @include button-remove();
    }
  }
}

.button.add-holiday {
  margin:   5px 3px;
}