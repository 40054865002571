span.op-period-time {
  display: block;
}

tr.op-row-day.highlighted td,
tr.op-row-day.highlighted th,
.op-list-overview .highlighted {
  color: $color-green;
}

span.op-period-time.highlighted {
  color: $color-green;
}

span.op-closed {
  color: $color-red;
}

span.op-open {
  color: $color-green;
}

span.op-next-period,
span.op-today {
  display: block;
}
