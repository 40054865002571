.op-irregular-openings {

  tr.op-irregular-opening.highlighted {

    td, th {
      color: $color-green;
    }
  }

  dd.highlighted, dt.highlighted {
    color: $color-green;
  }
}