@mixin input-gray () {
  background:   #efefef;
  border:       1px solid #cccccc;

  box-shadow:   none;
  color:        #333333;
}

@mixin button-remove () {
  color: $color-red;
  border: 1px solid $color-red;

  background: desaturate(lighten($color-red, 45%), 30%);

  box-shadow: none;

  padding-top: 3px;
}